import React from 'react';
import PropTypes from 'prop-types';
import ArticleStripComponent from 'lmel-components/core/article-strip';
import ErrorBoundary from 'lmel-components/core/error-boundary';
import { withTranslation } from 'react-i18next';

const ArticleStrip = props => {
  return (
    <ErrorBoundary>
      <ArticleStripComponent
        translate={props.t}
        {...props}
        pattern="shape-of-water-03"
        patternColor="neon-yellow"
      />
    </ErrorBoundary>
  );
};

ArticleStrip.propTypes = {
  t: PropTypes.func,
};

export default withTranslation()(ArticleStrip);
